/* @flow */

import React, { useState, useContext } from "react";
import { useClient } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { AnalyticsContext } from "@crossroads/analytics";
import { QuoteData } from "state/data";
import CustomerServiceLink from "pages/Checkout/CustomerServiceLink";
import { useData } from "crustate/react";
import { setQuoteDiscountCode, removeQuoteDiscountCode } from "state/quote";
import useFormat from "helpers/hooks/use-format";
import ButtonLink from "components/ButtonLink";
import ProductCard from "components/ProductCard";
import CartSummary from "components/CartSummary";

import { CheckoutCart } from "@crossroads/shop-views";

import styles from "./styles.scss";

const Cart = (): React$Node => {
  const t = useTranslate();
  const gaContext = useContext(AnalyticsContext);
  const quote = useData(QuoteData);
  const { formatPrice } = useFormat();
  const [open, setOpen] = useState(true);

  if (quote.state === "LOADING" || !quote.data) {
    return null;
  }

  return (
    <CheckoutCart
      analytics={{ context: gaContext }}
      QuoteData={QuoteData}
      formatPrice={formatPrice}
      removeQuoteDiscountCode={removeQuoteDiscountCode}
      setQuoteDiscountCode={setQuoteDiscountCode}
      useClient={useClient}
      SummaryField={
        <CartSummary open={open} setOpen={setOpen}>
          <div className={styles.submitButtonContainer}>
            <ButtonLink
              className={styles.submitButton}
              variant="primary"
              to="/checkout/overview"
            >
              {t("CART.TO_CHECKOUT")}
            </ButtonLink>
          </div>
        </CartSummary>
      }
      BtmColSecondaryField={<CustomerServiceLink />}
      ProductCardComponent={ProductCard}
    />
  );
};

export default Cart;
